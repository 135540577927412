import './App.css';
import firebase from 'firebase/compat/app';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Reservation from './pages/Reservation';
import ReservationCancel from './pages/ReservationCancel';
import NotFound from './pages/NotFound';

firebase.initializeApp(require(process.env.REACT_APP_FIREBASE).default);


function App() {
  return (
      <Router>
          <Routes>
              <Route path="/:restoName" element={<Reservation />} /> {/* Use <Reservation /> */}
              <Route path="/:restoName/cancel/:id" element={<ReservationCancel />} /> {/* Use <ReservationCancel /> */}
              <Route path="*" element={<NotFound />} /> {/* Use <NotFound /> */}
          </Routes>
      </Router>
  );
}

export default App;
