// write a function component named ReservationCancel. This compoment should render a div element with the text "Reservation Cancelled". The component should be exported as default.

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
dayjs.locale('fr')

const getResto = async (restoName) => {
  const docs = await firebase.firestore()
    .collection('Restaurant')
    .where('urlName', '==', restoName)
    .get();

  if (!docs.empty) {
    const data = {
      id: docs.docs[0].id,
      ...docs.docs[0].data(),
    }

    return data;
  }
  else {
    return null;
  }
}

const ReservationCancel = () => {
  const { restoName, id } = useParams();
  const reservationId = id;

  const [resto, setResto] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Added state for loading

  useEffect(() => {
    getResto(restoName.toLowerCase()).then(resto => {
      if (resto === null)
        setError('Le restaurant n\'existe pas');
      else {
        setResto(resto);
      }
    });
  }, [restoName]);

  useEffect(() => {
    if (resto !== null) {
      firebase.firestore()
        .collection('Restaurant')
        .doc(resto.id)
        .collection('Reservation')
        .doc(reservationId)
        .onSnapshot(doc => {
          if (doc.exists) {
            setReservation({
              id: doc.id,
              ...doc.data(),
            });
          }
          else {
            setReservation(null);
          }
        });
    }
  }, [resto?.id, reservationId, resto?.status]);

  useEffect(() => {
    if (resto !== null)
      document.title = 'Reservation ' + resto.reservationSettings.restoName;
  }, [resto]);

  const cancelReservation = () => {
    setLoading(true);

    setTimeout(async () => {
      await firebase.firestore()
        .collection('Restaurant')
        .doc(resto.id)
        .collection('Reservation')
        .doc(reservation.id)
        .update({ status: 5 });

      setLoading(false);
      console.log('Reservation cancelled successfully');
    }, 1500);
  }

  const getStatus = (status) => {
    if (status === 1)
      return 'En attente';
    else if (status === 2)
      return 'Confirmée';
    else if (status === 3)
      return 'Annulée';
    else if (status === 4)
      return 'Annulée';
    else if (status === 5)
      return 'Annulée';
  }

  if (reservation !== null) {
    const date = new Date(reservation.reservationDate.seconds * 1000 + reservation.reservationDate.nanoseconds / 1000000);
    const readableDate = dayjs(date).format('DD MMMM YYYY à HH:mm');
    return (
      <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 text-center">
        <div className="text-2xl font-semibold text-gray-800">Votre réservation</div>
        <div className="text-2xl font-semibold text-gray-800">{resto.reservationSettings.restoName}</div>
        <div className="text-lg text-blue-500">{readableDate}</div>
        <div className="text-lg text-gray-600">{reservation.firstname} {reservation.lastname}</div>
        <div className="text-lg text-gray-600">{reservation.nbAdult} personnes</div>
        <div className="text-lg">Status: <span className={`${getStatus(reservation.status) === 'Annulée' ? 'text-red-500' : 'text-gray-600'}`}>{getStatus(reservation.status)}</span></div>
        <div>
          {reservation.status === 2 && (
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded-full"
              onClick={cancelReservation}
              disabled={loading} // Disable button while loading
            >
              {loading ? 'Annulation en cours...' : 'Annuler votre réservation'}
            </button>
          )}
        </div>
      </div >
    );
  }

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4 text-center">
      Réservation Annulée
    </div>
  );

}

export default ReservationCancel;
