import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import ReservationForm from 'reservation-form-easoft';

const getResto = async (restoName) => {
  const docs = await firebase.firestore()
    .collection('Restaurant')
    .where('urlName', '==', restoName)
    .get();

  if (!docs.empty) {
    const data = {
      id: docs.docs[0].id,
      ...docs.docs[0].data(),
    }

    return data;
  }
  else {
    return null;
  }
}

const addReservation = (resto, reservation) => {
  reservation = !resto.reservationSettings.autoConfirm ? { ...reservation, status: 1 } : { ...reservation, status: 3 }
  return firebase.firestore()
    .collection('Restaurant')
    .doc(resto.id)
    .collection('Reservation')
    .add(reservation);
}


const Reservation = () => {
  const { restoName } = useParams();
  const [resto, setResto] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getResto(restoName.toLowerCase()).then(resto => {
      if (resto === null)
        setError('Le restaurant n\'existe pas');
      else
        setResto(resto);
    });
  }, []);

  useEffect(() => {
    if (resto !== null)
      document.title = 'Reservation ' + resto.reservationSettings.restoName;
  }, [resto]);

  const onConfirm = useCallback((reservation) => {
    return addReservation(resto, reservation)
  }, [resto]);

  if (resto !== null) {
    return (
      <ReservationForm
        reservationSettings={{ imgPath: resto.photos?.profilPath ?? 'https://picsum.photos/600/400', ...resto.reservationSettings }}
        onConfirm={onConfirm}
        restoId={resto.id}
      />
    )
  }

  if (error !== null) {
    return <h1>{error}</h1>
  }

  else
    return (
      <h1>Chargement...</h1>
    )
}

export default Reservation;